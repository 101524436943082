
import { defineComponent } from 'vue';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default defineComponent({
  name: "LoadingSpinner",
  components: {
    LottieAnimation,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
});
