
import {defineComponent} from 'vue';
import ButtonPrimary from "@/components/Button.vue";
import {client} from "@/server";
import {WebflowCollectionResponseInterface} from "@/interfaces/webflowCollection.interface";
import InputField from "@/components/InputField.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: 'DashboardView',
  components: {ButtonPrimary, InputField, LoadingSpinner},
  data() {
    return {
      preloader: {
        text: "",
        show: false
      },
      recipesUrl: `/integrations/webflow/collections/${process.env.VUE_APP_WEBFLOW_RECIPES_COLLECTION_ID}/items`,
      authorsUrl: `/integrations/webflow/collections/${process.env.VUE_APP_WEBFLOW_USERS_COLLECTION_ID}/items`,
      recipes: [] as Array<Record<string, any>>,
      tabs: ["my-recipes", "all-recipes"] as Array<string>,
      currentTab: "my-recipes" as string,
      newProductMenu: false as boolean,
      newRecipe: {
        name: "",
        image: "",
        description: "",
      } as Record<string, any>,
      errors: [],
      fileName: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    myRecipes() {
      const recipes = JSON.parse(JSON.stringify(this.recipes));
      return recipes.filter(recipe => recipe.author === this.user.webflow_author_id);
    },
  },
  mounted() {
    this.getRecipes();
  },
  methods: {
    resetLoader() {
      this.preloader.show = false;
      this.preloader.text = "";
    },
    newProduct() {
      this.newProductMenu = true;
    },
    logout() {
      this.$store.commit('logout', {});
      this.$router.push("/login");
    },
    async createRecipe() {
      try {
        this.preloader.show = true;
        this.preloader.text = "Creating recipe...";
        const recipe = await client.post("/rest/collections/recipes", this.newRecipe);
        this.newRecipe.image = recipe.data.data.image;
        this.newRecipe["author"] = this.user.webflow_author_id;
        this.newRecipe["_archived"] = false;
        this.newRecipe["_draft"] = false;
        await client.post(this.recipesUrl, {
          fields: this.newRecipe
        });
        this.newRecipe = {
          name: "",
          image: "",
          description: "",
        } as Record<string, any>;
        this.newProductMenu = false;
        await this.getRecipes();
        this.resetLoader();
        this.fileName = "";
      } catch (e) {
        this.fileName = "";
        console.error(e)
        this.resetLoader();
      }
    },
    setActiveTab(tab: string) {
      this.currentTab = tab;
    },
    async getRecipes() {
      try {
        const recipes: { data: WebflowCollectionResponseInterface } = await client.get(this.recipesUrl);
        const authors: { data: WebflowCollectionResponseInterface } = await client.get(this.authorsUrl);
        this.recipes = recipes.data.items.map((obj) => {
          const author = authors.data.items.find(author => author._id === obj.author) as Record<string, any>;
          obj["author_name"] = author.name;
          return obj
        });
      } catch (e) {
        console.error(e)
      }
    },
    handleFileUpload() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const file = this.$refs.file.files[0];
      this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = (e) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.newRecipe.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  }
});
