
import { ref } from "vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "InputField",
  setup() {
    const input = ref("");
    return { input };
  },
  props: {
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: "text"
    }
  },
})
