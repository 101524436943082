
import {defineComponent} from "vue";

export default defineComponent({
  name: "ButtonPrimary",
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "button"
    }
  }
})
