
import {defineComponent} from 'vue';
import InputField from "@/components/InputField.vue";
import ButtonPrimary from "@/components/Button.vue";
import {client} from "@/server";
import {checkForErrors} from "@/utils";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: 'LoginView',
  components: {ButtonPrimary, InputField, LoadingSpinner},
  data() {
    return {
      preloader: {
        text: "",
        show: false
      },
      email: "",
      password: "",
      errors: [],
    };
  },
  methods: {
    resetLoader() {
      this.preloader.show = false;
      this.preloader.text = "";
    },
    async login() {
      try {
        this.preloader.show = true;
        this.preloader.text = "Logging in...";
        const userData = await client.post('/auth/accounts/sign-in/email', {
          email: this.email,
          password: this.password
        });
        const userObject = await client.get(`/rest/collections/users/${userData.data.data.uid}`);
        this.$store.commit('saveUser', {
          uid: userData.data.data.uid,
          name: userObject.data.data.name,
          email:  userData.data.data.email,
          roles:  userObject.data.data.roles,
          webflow_author_id: userObject.data.data.webflow_author_id,
          token:  userData.data.data.idToken,
          refreshToken:  userData.data.data.refreshToken,
        });
        this.resetLoader();
        this.$router.push("/dashboard");
      } catch (e: any) {
        this.resetLoader();
        console.error(e)
        if (checkForErrors(e.response)) {
          this.errors = e.response.data.errors;
        }
      }
    }
  }
});
