import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e71dad4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "backdrop" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "animation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_animation = _resolveComponent("lottie-animation")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_lottie_animation, { path: "lottie/spinner.json" })
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
    ])
  ], 512)), [
    [_vShow, _ctx.show]
  ])
}